@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Alata&display=swap");

.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.js_alert,
.alert {
  position: fixed;
  top: 4.5rem;
  right: 20px;
  width: auto;
  max-width: 400px;
  max-height: 200px;
  overflow: auto;
}

.form-input,
.form-checkbox,
.form-select {
    border-color: #c4c4c4;
    &:focus {
        box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.3);
    }
}

.form-checkbox {
    height: 1.1rem;
    width: 1.1rem;
}

header.fixed {
    transition: all ease-in-out 0.2s;
}

header.notHome {
    @apply h-16 bg-gray-800 text-white;
}

header.scrollDown,
header.scrollUp {
    color: #fff;
    background-color: #4a5568;
    height: 4rem !important;
}

header.scrollDown {
    transform: translateY(-4rem);
}

header.scrollUp {
    transform: translateY(0);
}

.dropdown:hover .dropdown-hover\:block {
    display: block !important;
}

.min-w-6 {
    min-width: 180px;
}

.bg-gradient-indigo-blue {
    background-image: linear-gradient(to right, #667eea, #6db7f0);
}

.bg-gradient-gray-white {
    background-image: linear-gradient(to bottom, #fff, #d6e2eb);
}

.bg-gray-brand {
    background-color: #d6e2eb;
}

.bg-gradient-white-gray {
    background-image: linear-gradient(to top, #fff, #d6e2eb);
}

.btn-brand {
    background-image: linear-gradient(to right, #667eea, #6db7f0);
    transition: background-image 1s ease;
    padding: 0.75rem 2.5rem;
    border: none;
    color: #ffffff;

    &:hover {
        background-image: linear-gradient(to right, #6db7f0, #667eea);
    }
}

.svg-h-10 {
    height: 10%;
}

@media (max-width: 767px) {
    .svg-h-10 {
        height: 4%;
    }
}

/*
==============================
mobile nav section
==============================
*/

@media (max-width: 1023px) {
    #mobile-menu {
        position: fixed;
        z-index: 1000;
        left: 0;
        top: 0;
        bottom: 0;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100vh;
        width: 250px;
        background: #fff;
        transition: 0.2s ease-in-out;
        transform: translateX(-250px);
    }
    #mobile-menu-overlay {
        background-color: rgba($color: #000000, $alpha: 0.6);
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 999;
        height: 100vh;
        width: 100vw;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 10px;
            color: #fff;
        }
    }
}

/*
==============================
modal section
==============================
*/
body.modal-active {
    overflow-x: hidden;
    overflow-y: visible !important;
}

.loading {
    display: none;
}
